import { getSizeUnit } from "@utils/index";
import { useTranslation } from "react-i18next";
import FileItem from "./FileItem";
import { FileObject } from "./interface";
import Table from "../Table";

interface ExplorerTableProps {
  files: FileObject[];
  selected: FileObject[];
  setSelected(value: FileObject[]): any;
  onChangeDir(fileName: string): any;
  onOpenFile?(fileName: string): any;
}

export default function ExplorerTable({
  files,
  selected,
  setSelected,
  onChangeDir,
  onOpenFile,
}: ExplorerTableProps) {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("common.header.name"),
      dataIndex: "name",
      className: "min-w-[12rem]",
      render: (record: FileObject) => (
        <FileItem
          name={record.name}
          type={record.type}
          onOpen={() => openFileHandler(record)}
        />
      ),
    },
    {
      title: t("common.timestamp.modifiedTime"),
      dataIndex: "modifiedTime",
    },
    {
      title: t("common.header.size"),
      dataIndex: "size",
      render: (record: FileObject) => getSizeUnit(record.size) || "-",
    },
  ];

  function openFileHandler(file: FileObject) {
    if (file.type === "file") {
      if (onOpenFile) {
        onOpenFile(file.name);
      }
    } else {
      onChangeDir(file.name);
    }
  }

  return (
    <Table
      columns={columns}
      dataSource={files}
      rowKey="name"
      selected={selected}
      rowSelection={{ onChange: setSelected }}
    />
  );
}
