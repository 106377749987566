import { UploadingFileItem } from "./upload";
import { ChunkEtag } from "./chunk";

/*
================================
Interface for file uploading
status and callback functions
===============================
*/
export interface LargeFileStatus {
  uploadedSum: number;
  parts: ChunkEtag[];
}

export interface onUploadedFunctionProps {
  (
    e: ProgressEvent | null,
    uploadingFile: UploadingFileItem,
    hasRefresh: boolean
  ): void;
}

export interface UploadTrackerProps {
  numOfFilesUploaded: number;
  largeFilesStatus: Record<number, LargeFileStatus>; // largeFilesStatus item's index maps to the first chunk's index of uploadingFiles
  uploadingFiles: UploadingFileItem[];
  onUploaded?: onUploadedFunctionProps;
  setUploadingFilesList?: (uploadingFiles: UploadingFileItem[]) => void;
}

/*
================================
Create a default upload tracker
===============================
*/
export function createUploadTracker() {
  return {
    numOfFilesUploaded: 0,
    largeFilesStatus: {},
    uploadingFiles: [],
    onUploaded: undefined,
    setUploadingFilesList: undefined,
  } as UploadTrackerProps;
}
