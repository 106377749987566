import { NotiTypes } from "@components/common";
import { TFunction } from "i18next";
import { HttpError } from "./http";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
  parse,
} from "date-fns";
import { moneyCharacter, DEFAULT_CONSOLE_APP } from "@src/constant";
import { checkLicense, getUserInfo } from "./api/account";
import { type TagInfo } from "@src/utils/api/project";
import { AppDispatch } from "@src/store";
import { addNotification, setUserInfo } from "@src/store/common";
import { globalFetcher } from "./globals";

export function classNames(...classes: any): string {
  // Allow the user to define conditional classNames
  // e.g.  classNames(show ? "visible" : "hidden")
  return classes.filter(Boolean).join(" ");
}

// render file size with fit unit, default unit is Byte
const SIZE_UNIT = ["Byte", "KB", "MB", "GB"];
export function getSizeUnit(size: number | string) {
  if (size === "") return "";

  let curVal = Number(size);
  let unitIndex = 0;
  while (unitIndex < SIZE_UNIT.length && curVal >= 1024) {
    ++unitIndex;
    curVal /= 1024;
  }
  return `${parseFloat(curVal.toFixed(1))} ${SIZE_UNIT[unitIndex]}`;
}

interface keyMapType {
  [key: string]: any;
}

/**
 * Convert form data to object
 * @param {FormData} data - form data
 * @param {keyMapType} keyMap - mapping original key in form data to key in returned object
 * @returns {keyMapType}
 */
export function formDataToObject(
  data: FormData,
  keyMap: keyMapType = {}
): keyMapType {
  let object: keyMapType = {};
  for (const [k, v] of data) {
    if (k in keyMap) {
      object[keyMap[k]] = v;
    } else {
      object[k] = v;
    }
  }
  return object;
}

export function isValidKey(
  key: string | number | symbol,
  object: object
): key is keyof typeof object {
  return key in object;
}

export const sendErrorNotification = (error: any, dispatch: AppDispatch) => {
  const notification = {
    type: NotiTypes.FAIL,
    title: "",
  };

  if (error instanceof HttpError) {
    notification.title = `${error.statusCode}: ${error.message}`;
  } else {
    notification.title = String(error);
  }

  dispatch(addNotification(notification));
  console.error(error);
};

export const sendFailureNotification = (
  title: string,
  dispatch: AppDispatch
) => {
  dispatch(
    addNotification({
      type: NotiTypes.FAIL,
      title,
    })
  );
};

export const sendSuccessNotification = (
  title: string,
  dispatch: AppDispatch
) => {
  dispatch(
    addNotification({
      type: NotiTypes.SUCCESS,
      title,
    })
  );
};

export const toMoney = (num: number, n = 2) => {
  let str = (Math.round(num * Math.pow(10, n)) / Math.pow(10, n)).toString(); // n 幂
  let rs = str.indexOf(".");
  //判定如果是整数，增加小数点再补0
  if (rs < 0) {
    rs = str.length;
    str += ".";
  }
  while (str.length <= rs + 2) {
    str += "0";
  }
  return str;
  // const str = parseFloat(num.toFixed(2));

  // if (str.lastIndexOf(".") < 0) return str + ".00";
  // else if (str.split(".")[1].length === 1) return str + "0";
  // else return str;
};

export const formatMoney = (account: number, n = 2) =>
  `${account >= 0 ? "" : "- "}${moneyCharacter} ${toMoney(
    Math.abs(account),
    n
  )}`;

export const formatDateDiff = (time: string | number, translate: TFunction) => {
  const date = new Date(time);
  const now = new Date();
  // minute count
  let timeDiff = differenceInMinutes(now, date);
  if (timeDiff < 1) return translate("component.common.date.lessOneMinute");
  if (timeDiff < 60)
    return ` ${timeDiff} ${translate("component.common.date.minuteAgo")}`;
  // hour count
  timeDiff = differenceInHours(now, date);
  if (timeDiff < 24)
    return ` ${timeDiff} ${translate("component.common.date.hourAgo")}`;
  // day count
  timeDiff = differenceInDays(now, date);
  if (timeDiff < 30)
    return ` ${timeDiff} ${translate("component.common.date.dayAgo")}`;
  // is in the same year?
  if (date.getFullYear() === now.getFullYear())
    return ` ${format(date, "MM-dd")}`;
  else return ` ${format(date, "yyyy-MM-dd")}`;
};

// e.g. getObjectAttribute(obj, "a.b") === obj.a.b
export const getObjectAttribute = (
  target: Record<string, any>,
  attrStr: string
): string => {
  const attributes = attrStr.split(".");
  let ans: Record<string, any> | string = target;
  for (const attr of attributes) {
    ans = (ans as Record<string, any>)[attr];
  }
  return ans as string;
};

export const formatOptions = (arr: number[]) =>
  arr.map((num) => ({
    name: String(num),
    value: num,
  }));

export const renderInfo = (data: any) => (data || data === 0 ? data : "-");

export const getTagName = (tagInfos: TagInfo[], tag: string): string => {
  for (const tagInfo of tagInfos) {
    for (const { name, value } of tagInfo.tagValues) {
      if (value === tag) return name;
    }
  }
  return "";
};

export async function copyToClipboard(textToCopy: string) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;

    // Move textarea out of the viewport so it's not visible
    textArea.style.width = "0px";
    textArea.style.height = "0px";

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
}

export const throttle = (func: Function, wait: number) => {
  let timeout: any = null;
  return function (...args: any[]) {
    if (timeout) return;
    func(...args);
    timeout = setTimeout(() => {
      timeout = null;
    }, wait);
  };
};

export const debounce = (func: Function, wait: number) => {
  let timeout: any = null;
  return function (...args: any[]) {
    timeout && clearTimeout(timeout);

    timeout = setTimeout(() => {
      func(...args);
      timeout = null;
    }, wait);
  };
};

export const refreshUserInfo = async (
  dispatch: AppDispatch,
  navigate: Function,
  t: TFunction
) => {
  if (globalFetcher.accessToken === "") {
    dispatch(setUserInfo(null));
    return;
  }

  try {
    const {
      username,
      email,
      user_image: avatarSrc,
      account_type: accountType,
      license = "",
      group,
      userId,
      mainUserId,
      mobile,
    } = await getUserInfo();

    let expiredTime: Date | undefined;

    if (license) {
      const { status, expire_time } = await checkLicense({ license });
      expiredTime = parse(expire_time, "yyyyMMddHHmmss", new Date());
      const now = new Date();

      const diffDays = differenceInDays(expiredTime, now);
      if (status !== "ALIVE" || diffDays <= -1)
        throw Error(String(t("account.tooltip.licenseExpired")));
      else {
        if (diffDays < 1)
          sendErrorNotification(t("account.tooltip.licenseWarning"), dispatch);
      }
    }

    dispatch(
      setUserInfo({
        username,
        email,
        avatarSrc,
        accountType,
        license,
        expiredTime: expiredTime ? format(expiredTime, "yyyy-MM-dd") : "",
        group,
        userId: String(userId),
        mainUserId,
        mobile,
      })
    );
  } catch (e: any) {
    const { pathname } = window.location;
    if (
      e instanceof HttpError &&
      (e.statusCode === 403 || e.statusCode === 401) &&
      (pathname.startsWith("/console") ||
        pathname.startsWith("/billing") ||
        pathname.startsWith("/user"))
    ) {
      dispatch(setUserInfo(null));
      navigate({
        pathname: "/account/signin",
        query: {
          redirectUrl:
            window.location.href.slice(window.location.origin.length) ||
            `/console/${DEFAULT_CONSOLE_APP}`,
        },
      });
    }
    globalFetcher.clearAccessToken();
    sendErrorNotification(e, dispatch);
  }
};

// export all functions from utils as defualt
const exports = {
  classNames,
  getSizeUnit,
  formDataToObject,
  isValidKey,
  sendErrorNotification,
  sendFailureNotification,
  sendSuccessNotification,
  toMoney,
  formatMoney,
  formatDateDiff,
  getObjectAttribute,
  formatOptions,
  renderInfo,
  getTagName,
  copyToClipboard,
  throttle,
  debounce,
};

export default exports;
