import { TFunction } from "i18next";
import { globalFetcher, SERVER } from "../globals";
import { request } from "../http";
import { PagerRequest, PagerResponse } from "./interface";

export type accountType = "personal" | "company";

/*
=====================
Signin
=====================
*/
export interface SignInRequest {
  username?: string;
  password?: string;
  otp?: string;
  mobile?: string;
  rememberMe: string;
}

export interface SignInResponse {
  accessToken: string;
  accessExpire: string;
}

export const signIn = async (
  params: SignInRequest,
  t: TFunction
): Promise<SignInResponse> =>
  request(globalFetcher, `${SERVER}/api/user/login`, params, "POST", false, t);

/*
=====================
Signup
=====================
*/
export interface SignUpRequest {
  username: string;
  password: string;
  rememberMe?: string;
  mobile?: string;
  otp?: string;
  mainUserId?: string;
  invitationCode?: string;
  captcha?: {
    captchaKey: string;
    captchaCode: string;
  };
}

export interface SignUpResponse {
  userId: string;
}

export const signUp = async (
  params: SignUpRequest,
  t: TFunction
): Promise<SignUpResponse> =>
  request(
    globalFetcher,
    `${SERVER}/api/user/register`,
    params,
    "POST",
    false,
    t
  );

/*
=====================
Reset
=====================
*/
export interface ResetPasswordRequest {
  email?: string;
  userName: string;
  otp?: string;
  oldPassword?: string;
  password: string;
}

export interface ResetPasswordResponse {}

export const resetPassword = async (
  params: ResetPasswordRequest
): Promise<ResetPasswordResponse> =>
  request(
    globalFetcher,
    `${SERVER}/api/user/resetpassword`,
    params,
    "POST",
    false
  );

export interface UserInfoResponse {
  username: string;
  email: string;
  user_image?: string;
  account_type: accountType;
  license?: string;
  group: string[];
  userId: string;
  mainUserId: string;
  mobile: string;
}

export const getUserInfo = async (): Promise<UserInfoResponse> =>
  request(globalFetcher, `${SERVER}/api/user/info`, undefined, "GET");

export interface UserNotification {
  id: string;
  content: string;
  timeStampUnixMs: number;
  status: string;
}

export interface UserNotificationsResponse {
  notifications: UserNotification[];
  pager: PagerResponse;
}

// We have cache for Pager{1, 10} (default size),
// so in pulling, just leave params blank.
export const getUserNotifications = async (params: {
  pager?: PagerRequest;
}): Promise<UserNotificationsResponse> =>
  request(globalFetcher, `${SERVER}/api/user/notification/get`, params);

export const markUserNotificationsAsRead = async (params: {
  ids: string[];
}): Promise<{ count: number }> =>
  request(globalFetcher, `${SERVER}/api/user/notification/read`, params);

export interface UserNotificationPullDataResponse {
  notebookSupportResizeUnixMs: number;
  notification: string;
}

export const getUserNotificationPullData = async (): Promise<{
  data: UserNotificationPullDataResponse;
}> => request(globalFetcher, `${SERVER}/api/user/notification/pulldata`);

interface LicenseResponse {
  status: string;
  expire_time: string;
}

export const checkLicense = async (params: {
  license: string;
}): Promise<LicenseResponse> =>
  request(globalFetcher, `${SERVER}/api/user/license/heartbeat`, params);

/*
=====================
Log out
=====================
*/
export const logOut = async (): Promise<void> =>
  request(globalFetcher, `${SERVER}/api/user/logout`, {});

/*
=====================
Get OTP
=====================
 */

// purpose for /api/user/otp
export const OtpPurposeLogin = "login";
export const OtpPurposeRegister = "register";

// purpose for /api/user/otp/authenticated
export const OtpPurposeReset = "reset";
export const OtpPurposeChangeMobile = "changeMobile";

export interface OtpRequest {
  mobile?: string;
  purpose: string;
}

export interface OtpResponse {
  sent: boolean;
  nextRetryUnixMs: number;
}

export const getOtp = async (
  params: OtpRequest,
  t: TFunction
): Promise<OtpResponse> =>
  request(
    globalFetcher,
    `${SERVER}/api/user/otp`,
    params,
    undefined,
    undefined,
    t
  );

export const getOtpAuthenticated = async (
  params: OtpRequest,
  t: TFunction
): Promise<OtpResponse> =>
  request(
    globalFetcher,
    `${SERVER}/api/user/otp/authenticated`,
    params,
    undefined,
    undefined,
    t
  );

export interface SubUser {
  userId: string;
  username: string;
}

interface GetSubUserListResponse {
  users: SubUser[];
  pager: PagerResponse;
}

export const getSubUserList = async (params: {
  pager: PagerRequest;
}): Promise<GetSubUserListResponse> =>
  request(globalFetcher, `${SERVER}/api/user/subuser/list`, params);

export interface UpdateUserInfoRequest {
  otp?: string;
  mobile?: string;
}

export const updateUserInfo = async (params: UpdateUserInfoRequest) =>
  request(globalFetcher, `${SERVER}/api/user/update`, params);

export interface SshKeyGetResponse {
  keys: SshKey[];
}

export interface SshKey {
  id: string;
  title: string;
  key: string;
  createTimeUnixMs: number;
  lastUsedTimeUnixMs: number;
}

export const getSshKey = async (): Promise<SshKeyGetResponse> =>
  request(globalFetcher, `${SERVER}/api/user/sshkey/get`, {});

export interface SshKeySetRequest {
  title?: string;
  key: string;
}

export interface SshKeySetResponse {
  id: string;
}

export const setSshKey = async (
  params: SshKeySetRequest
): Promise<SshKeySetResponse> =>
  request(globalFetcher, `${SERVER}/api/user/sshkey/set`, params);

export interface SshKeyDeleteRequest {
  id: string;
}

export const deleteSshKey = async (params: SshKeyDeleteRequest) =>
  request(globalFetcher, `${SERVER}/api/user/sshkey/delete`, params);

export interface InviteInfo {
  code: string;
  invitedUserCount: number;
  invitedVoucherAmountSum: number;
}

export const getInvitation = async (): Promise<InviteInfo> =>
  request(globalFetcher, `${SERVER}/api/user/invitation/get`);

export interface InvitePolicy {
  inviterVoucherAmount: number;
  inviteeVoucherAmount: number;
}

export const getInvitationPolicy = async (): Promise<InvitePolicy> =>
  request(globalFetcher, `${SERVER}/api/user/invitation/policy`);

export interface CaptchaClickShapeResponse {
  captchaKey: string;
  imageBase64: string;
  thumbBase64: string;
}

export const getCaptchaClickShape =
  async (): Promise<CaptchaClickShapeResponse> =>
    request(globalFetcher, `${SERVER}/api/user/captcha/click-shape`);

export interface CaptchaClickShapeCheckRequest {
  dots: number[];
  key: string;
}

export interface CaptchaClickShapeCheckResponse {
  found: boolean;
  captchaCode: string;
  message: string;
}

export const checkCaptchaClickShape = async (
  params: CaptchaClickShapeCheckRequest
): Promise<CaptchaClickShapeCheckResponse> =>
  request(
    globalFetcher,
    `${SERVER}/api/user/captcha/click-shape/check`,
    params
  );
