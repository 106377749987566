// import { DEFAULT_CONSOLE_APP } from "@src/constant";

interface NavBarLink {
  id: string;
  text: string;
  href: string;
}

export const NAVBAR_LINKS: NavBarLink[] = [
  // {
  //   id: "console",
  //   text: "component.common.navBar.console",
  //   href: `/console/${DEFAULT_CONSOLE_APP}`,
  // },
  { id: "billing", text: "billing.header.name", href: "/billing/overview" },
  {
    id: "admin",
    text: "admin.title",
    href: "/admin/account",
  },
];

// IS_DEV === "1" &&
//   NAVBAR_LINKS.push({ text: "billing.header.name", href: "/billing/overview" });
