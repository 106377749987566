import i18n from "i18next";
import { initReactI18next } from "react-i18next/initReactI18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import en from "./locales/en.json";
import zh from "./locales/zh.json";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  // Detect the language currently used by the user
  // doc: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // Inject the react-i18next instance
  .use(initReactI18next)
  // init i18next
  // config params doc: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: "zh",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      // en: {
      //   translation: en,
      // },
      zh: {
        translation: zh,
      },
    },
    returnNull: false,
  });

export default i18n;
