import Link from "next/link";
import { usePathname } from "next/navigation";
// import { BreadcrumbData } from "use-react-router-breadcrumbs";
import { HiChevronRight } from "react-icons/hi2";

interface Path {
  path: string;
  breadcrumb: string;
}

interface BreadcrumbProps {
  routes: Path[];
  // useBreadcrumbs(routes: Path[], options: any): BreadcrumbData[];
}

export default function BreadCrumbs({
  routes,
}: // useBreadcrumbs,
BreadcrumbProps): JSX.Element {
  // const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });
  const pathname = usePathname();

  const paths = routes.filter(({ path }) => pathname.includes(path));

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {paths.map(({ path, breadcrumb }, index) => (
          <li key={path}>
            <div className="flex items-center">
              <HiChevronRight
                aria-hidden="true"
                className="h-3 w-3 flex-shrink-0 text-gray-400"
              />
              {index < paths.length - 1 ? (
                <Link
                  href={path}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 hover:bg-gray-100 px-1.5 rounded transition-colors"
                  aria-current={path === pathname ? "page" : undefined}
                >
                  {breadcrumb}
                </Link>
              ) : (
                <span className="ml-4 text-sm font-medium text-gray-700 px-1.5">
                  {breadcrumb}
                </span>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
