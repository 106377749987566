import { useEffect, useState } from "react";
import { ProtectedFetcher } from "@utils/http";
import { useRouter } from "next/navigation";
import { globalFetcher } from "@utils/globals";
import {
  getUserNotificationPullData,
  logOut,
  type accountType,
} from "./api/account";
import { refreshUserInfo } from "@src/utils";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@src/store";
import { initNotificationData } from "@src/store/common";

export interface UserInfo {
  username: string;
  email: string;
  avatarSrc?: string;
  mobile: string;
  accountType: accountType;
  license: string;
  group: string[];
  userId: string;
  mainUserId: String;
  expiredTime: string;
}

/**
 * This hook returns user info and loading state.
 * User info is fetched from user server via HTTP request.
 * When loading is true, userInfo is null.
 * When loading is false, userInfo is an object (success) or null (failed).
 * HttpError with 401 status code will be ignored, and other errors will be thrown.
 * Environment variable REACT_APP_PUBLIC_SERVER must be set.
 * Side effects: If fetcher is not found in micro-app's global data, it will be initialized and added to the global data.
 */
export function useUser() {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    globalFetcher.initHooks(router.push, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // update user info
    refreshUserInfo(dispatch, router.push, t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFetcher.accessToken]);
}

export function useNotificationData() {
  const dispatch = useAppDispatch();

  const refreshNotificationData = async () => {
    try {
      const { data } = await getUserNotificationPullData();
      dispatch(initNotificationData(data));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    refreshNotificationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

/**
 * This hook returns a sign out function.
 * As microApp is initialized in useEffect of _app.tsx,
 * we have to setup fetcher in useEffect as well.
 */
export function useSignOut() {
  const [signOut, setSignOut] = useState<() => Promise<any>>(
    () => async () => null
  );

  const router = useRouter();

  useEffect(() => {
    async function signOutHandler(fetcher: ProtectedFetcher) {
      logOut();
      // clear access token
      fetcher.clearAccessToken();

      // change route to home page
      router.push("/");
    }

    setSignOut(() => async () => await signOutHandler(globalFetcher));
  }, [router]);

  return signOut;
}
