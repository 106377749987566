import { TFunction } from "i18next";
import { type AppDispatch } from "@src/store";
import { setUserInfo } from "@src/store/common";

/**
 * HttpError will be thrown when status code is not 2xx.
 * It has two main properties: statusCode and meesage.
 */
export class HttpError extends Error {
  public readonly statusCode: number;
  public readonly i18nCode: number = 0;

  constructor(statusCode: number, i18nCode: number, ...args: any[]) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpError);
    }
    this.name = "HttpError";
    this.statusCode = statusCode;
    if (i18nCode) {
      this.i18nCode = i18nCode;
    }
  }
}

// export class I18nHttpError extends HttpError {
//   public readonly i18nCode: number;
//   public readonly values: object;

//   constructor(i18nCode: number, message: string, values: object = {}) {
//     super(400, message);
//     if (Error.captureStackTrace) {
//       Error.captureStackTrace(this, I18nHttpError);
//     }
//     this.name = "I18nHttpError";
//     this.i18nCode = i18nCode;
//     this.message = message;
//     this.values = values;
//   }
// }

function extractErrorMessage(text: string) {
  try {
    const data = JSON.parse(text);
    return data.message;
  } catch (e) {
    return text;
  }
}

async function customFetch(
  method: string,
  url: string,
  headers: HeadersInit | undefined = undefined,
  body: BodyInit | null = null,
  t?: TFunction
) {
  const response = await fetch(url, {
    method,
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    headers,
    body,
  });
  if (!response.ok) {
    const body = await response.text();
    let data: any = {};
    try {
      data = JSON.parse(body);
    } catch (e) {
      throw new HttpError(
        response.status,
        0,
        `failed to parse response body as JSON, raw response body: ${
          response.status === 404 ? "404 Not Found" : body
        }`
      );
    }

    if (t && data.code && data.message) {
      // has i18n code
      throw new HttpError(
        response.status,
        data.code,
        t(`i18nErrorCode.${data.code}`, data.values)
      );
    } else if (data.message) {
      throw new HttpError(response.status, 0, extractErrorMessage(body));
    }
  }
  return response;
}

/**
 * Class to fetch protected resources (those need access token).
 * Using methods of this class, access token will be added automatically to HTTP header.
 * We use two tokens: access token and refresh token.
 * Access token is stored in memory (in instances of this class).
 * Refresh token is stored in cookie.
 * Issue: https://github.com/hpcaitech/Cloud-Platform/issues/111#issuecomment-1318261740
 */
export class ProtectedFetcher {
  accessToken: string;
  private readonly refreshUrl: string;
  // private navigate: Function | undefined;
  private dispatch: AppDispatch | undefined;

  constructor(refreshUrl: string, navigate?: Function) {
    this.accessToken = "";
    this.refreshUrl = refreshUrl;
    // this.navigate = navigate;
    // try to get access token from local storage
    this.fetchAccessTokenFromLocalStorage();
  }

  public initHooks(navigate: Function, dispatch: AppDispatch) {
    // this.navigate = navigate;
    this.dispatch = dispatch;
  }

  public updateAccessToken(accessToken: string) {
    this.accessToken = accessToken;
    this.storeAccessTokenInLocalStorage();
  }

  public fetchAccessTokenFromLocalStorage() {
    const accessToken =
      typeof window !== "undefined"
        ? window.localStorage.getItem("accessToken")
        : "";
    if (accessToken) {
      this.updateAccessToken(accessToken);
    }
  }

  public storeAccessTokenInLocalStorage() {
    window.localStorage.setItem("accessToken", this.accessToken);
  }

  public clearAccessToken() {
    this.accessToken = "";
    window.localStorage.removeItem("accessToken");
  }

  private async refresh(t?: TFunction) {
    try {
      const response = await customFetch(
        "GET",
        this.refreshUrl,
        undefined,
        null,
        t
      );
      const data = await response.json();
      // update access token
      this.updateAccessToken(data.accessToken);
      window.localStorage.setItem("accessToken", data.accessToken);
    } catch (e) {
      const { pathname } = window.location;
      if (
        e instanceof HttpError &&
        (e.statusCode === 403 || e.statusCode === 401) &&
        (pathname.startsWith("/console") ||
          pathname.startsWith("/billing") ||
          pathname.startsWith("/user"))
      ) {
        this.dispatch && this.dispatch(setUserInfo(null));
        typeof window !== "undefined" &&
          (window.location.href = "/account/signin");
      } else {
        throw e;
      }
    }
  }

  /**
   * Base method for all fetch api.
   * Access token and refresh token are updated automatically in this method.
   * There are diagrams in issue illustrating this method.
   */
  private async protectedFetch(
    method: string,
    url: string,
    headers: HeadersInit | undefined = undefined,
    body: BodyInit | null = null,
    withAuth: boolean = true,
    t?: TFunction
  ) {
    try {
      if (withAuth) {
        headers = {
          ...headers,
          Authorization: `Bearer ${this.accessToken}`,
        };
      }

      const response = await customFetch(method, url, headers, body, t);
      return response;
    } catch (e) {
      if (e instanceof HttpError && e.statusCode === 401) {
        // if there is no access token in the local storage
        // just return as the token is removed by logout
        await this.refresh();

        if (withAuth) {
          headers = {
            ...headers,
            Authorization: `Bearer ${this.accessToken}`,
          };
        }

        return await customFetch(method, url, headers, body, t);
      } else {
        throw e;
      }
    }
  }

  async get(
    url: string,
    headers: HeadersInit | undefined = undefined,
    withAuth: boolean = true,
    t?: TFunction
  ) {
    return await this.protectedFetch("GET", url, headers, null, withAuth, t);
  }

  /**
   * Post json
   * @param url
   * @param body - original object, DO NOT call JSON.stringify()
   * @param headers
   * @returns
   */
  async postJson(
    url: string,
    body: object,
    headers: HeadersInit | undefined = undefined,
    withAuth: boolean = true,
    t?: TFunction
  ) {
    return await this.protectedFetch(
      "POST",
      url,
      {
        ...headers,
        "Content-Type": "application/json",
      },
      JSON.stringify(body),
      withAuth,
      t
    );
  }

  async postFormData(
    url: string,
    body: FormData,
    headers: HeadersInit | undefined = undefined,
    withAuth: boolean = true
  ) {
    return await this.protectedFetch("POST", url, headers, body, withAuth);
  }
}

export const request = async (
  fetcher: ProtectedFetcher,
  url = "",
  params = {},
  method = "POST",
  withAuth: boolean = true,
  t?: TFunction
) => {
  if (method === "POST") {
    const resp = await fetcher.postJson(
      `${url}`,
      params,
      undefined,
      withAuth,
      t
    );
    const data = await resp.json();
    return data;
  } else if (method === "GET") {
    const resp = await fetcher.get(`${url}`, undefined, withAuth, t);
    const data = await resp.json();
    return data;
  }
};

const exports = {
  ProtectedFetcher,
  HttpError,
  request,
};

export default exports;
