// import Link from "next/link";
import { useTranslation } from "react-i18next";

interface IconItem {
  icon(props: React.ComponentProps<"svg">): JSX.Element | any;
  onClick(e?: React.MouseEvent<HTMLButtonElement>): any;
}

interface CardProps {
  href?: string;
  title: JSX.Element | string;
  description: string;
  infos?: (JSX.Element | string)[];
  icons: IconItem[];
  className?: string;
  onClick?: Function;
}

export default function Card({
  // href,
  title,
  description,
  infos,
  icons,
  className = "",
  onClick,
}: CardProps): JSX.Element {
  const { t } = useTranslation();

  const generateInfos = () => {
    if (!infos) return "";
    const ans: (JSX.Element | string)[] = [];

    infos.forEach((info, index) => {
      ans.push(
        <span key={index} className="inline-flex items-center">
          {info}
        </span>,
        <span key={`dot-${index}`} className="px-1.5 text-gray-300">
          •
        </span>
      );
    });
    ans.pop();
    return ans;
  };

  return (
    // <Link href={href}>
    <div
      className={`group relative overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm hover:border-blue-500 transition-colors duration-300 w-full mx-auto h-fit ${className}`}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <div className="flex flex-1 flex-col space-y-2 px-3 py-2 h-full">
        <div className="flex justify-between items-center h-6">
          <h3 className="font-medium text-gray-900 flex-1 truncate font-mono">
            {title}
          </h3>
          <span className="shrink-0 space-x-2 flex items-center ml-1">
            {icons.map((item, idx) => (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  item.onClick();
                }}
                key={`${title}-icons-${idx}`}
              >
                <item.icon className="w-4 h-4 text-gray-900 hover:text-blue-500 transition-colors duration-300" />
              </button>
            ))}
          </span>
        </div>
        <p className="h-5 text-sm text-gray-500 text-ellipsis line-clamp-1 whitespace-pre-line">
          {description || t("common.tip.noDescription")}
        </p>
        <div className="text-xs font-normal text-gray-400 flex items-center">
          {generateInfos()}
        </div>
      </div>
    </div>
    // </Link>
  );
}
